import React, { Fragment } from 'react';

export default function Contact() {
  return <Fragment>
    <div className="container-mini">
      <h1>Contactez-nous</h1>
      <div class="text-cg-wrapper">
        <p style={{ fontSize: '1.75em' }}>D’une manière générale, contactez la Société d’Economie Alpestre de votre département ou de votre canton qui coordonne le projet.</p>
        <p><strong>Administrateur du site :</strong></p>
        <p>Société d’Economie alpestre de Haute-Savoie<br />Service Sensibilisation<br />Tel : 04 50 88 37 74<br />Courriel : sea74sensibilisation@echoalp.com<br />105 avenue de genève<br />74000 Annecy</p>
      </div>
      <div className="mentions-legales-randonneurs"><img src="/images/randonneurs.png" aria-hidden={true} alt="" /></div>
      <img src="/images/chien.png" className="perso-footer perso-footer-chien" aria-hidden={true} alt="" />
    </div>
  </Fragment>
}
